import React, {useState, useEffect} from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Link } from "gatsby";
import { useMedia } from 'react-use';
import classNames from 'classnames';
import styles from './nav.module.less';
import img from 'assets/images/full-logo.png'
import Products from '../Products';
import Search from '../Search';
import {MenuOutlined, CloseOutlined} from '@ant-design/icons'
import logo from 'assets/images/mini-logo.png';
import Burger from '@animated-burgers/burger-squeeze';
import '@animated-burgers/burger-squeeze/dist/styles.css' 
import './burger-style-override.less';
import NavMenuItems from './NavMenuItems';

const ScrollspyMenu = ({
  navs = [],
  docsearchOptions,
  path,
  isHomePage,
  subTitleHref,
  subTitle
}) => {
  const SubTitleLink = (subTitleHref || '').startsWith('http') ? 'a' : Link;

  const [productMenuVisible, setProductMenuVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false)
  const [popupMenuVisible, setPopupMenuVisible] = useState(false);
  const onTogglePopupMenuVisible = () => {
    setPopupMenuVisible(!popupMenuVisible);
  };

  let productMenuHovering = false;


  const onProductMouseEnter = (e) => {
    productMenuHovering = true;
    e.persist();
    setTimeout(() => {
      if (e.target instanceof Element && e.target.matches(':hover')) {
        setProductMenuVisible(true);
      }
    }, 200);
  };
  const onProductMouseLeave = (e) => {
    e.persist();
    productMenuHovering = false;
    setTimeout(() => {
      if (productMenuHovering) {
        return;
      }
      setProductMenuVisible(false);
    }, 200);
  };
  const onToggleProductMenuVisible = () => {
    setProductMenuVisible(!productMenuVisible);
  };
  const isWide = useMedia('(min-width: 767.99px)', true);

  const productItemProps = isWide
    ? {
        onMouseEnter: onProductMouseEnter,
        onMouseLeave: onProductMouseLeave,
      }
    : {
        onClick: onToggleProductMenuVisible,
      };

      useEffect(() => {
        const handleScroll = window.addEventListener("scroll", () => {
          if (window.pageYOffset > 50) {
              setScrolled(true)
          } else {
            setScrolled(false)
          }
        })
    
        return () => {
          window.removeEventListener("scroll", handleScroll)
        }
      }, [])

      useEffect(() => {
        if (popupMenuVisible) {
          document.documentElement!.style.overflow = 'hidden';
        } else {
          document.documentElement!.style.overflow = '';
        }
        return () => {
          document.documentElement!.style.overflow = '';
        };
      }, [popupMenuVisible]);


      const menuIcon = !isWide ? ( 
          <Burger className={styles.beeper} isOpen={ popupMenuVisible }  onClick={onTogglePopupMenuVisible} />
      ) : null;
console.log(path)
  return (
    <header
        id='header'
      className={classNames(styles.header, {
        [styles.transparent]: !productMenuVisible && (!scrolled && isHomePage),
        [styles.isHomePage]: isHomePage,
        [styles.fixed]: true,
      })}
    >
      <div className={styles.container}>
      <div className={styles.left}>

            <h1>
            <a href='https://sortd.io' ><img src={img} /></a>
          </h1>
          {subTitle && (
            <>
              <span className={styles.divider} />
              <h2 className={styles.subProduceName}>
                {React.createElement(
                  SubTitleLink,
                  {
                    [SubTitleLink === 'a' ? 'href' : 'to']:
                      typeof subTitleHref === 'undefined'
                        ? `/`
                        : subTitleHref,
                  },
                  subTitle,
                )}
              </h2>
            </>
          )}
          <Search />

        </div>
        <nav className={styles.nav}>

        <ul
        className={classNames(styles.menu, {
          [styles.popup]: !isWide,
          [styles.popupHidden]: !popupMenuVisible,
        })}
        >
          <li key={'home'} className={path === '/' && styles.activeItem} onClick={() => setPopupMenuVisible(false)}>
              <Link to={'/'}>Home</Link>
          </li>
        <li {...productItemProps} className={path.split('/')[1] === 'products' && styles.activeItem} onClick={() => setProductMenuVisible(true)}>
          <a>
            {('Products')}
            <img
              src={logo}
              alt="antv logo arrow"
              className={classNames(styles.arrow, {
                [styles.open]: productMenuVisible,
              })}
            />
          </a>
          <Products
            className={styles.productsMenu}
            show={productMenuVisible}
            rootDomain={'sortd.io'}
            setHidden={() => {
              setProductMenuVisible(false)
              setPopupMenuVisible(false)
            }}
          />
        </li>
        {navs && navs.length ? <span onClick={() => setPopupMenuVisible(false)}><NavMenuItems navs={navs} path={path} /></span> : null}

        </ul>
        {menuIcon}
        </nav>
        </div>
        </header>
  );
};

export default ScrollspyMenu;
