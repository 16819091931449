import React from 'react';
import { Row, Col } from 'antd';
import {Link} from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import './style.less'

class Footer extends React.Component<{
  location: {
    pathname: string;
  };
}> {

  render() {
    return (
      <footer id="footer" className='section'>
      <div className="footer-wrap">
        <Row>
          <Col md={6} sm={24} xs={24}>
            <div className="footer-center">
              <h2>
                Quick Links
              </h2>
              <div>
                <Link to="/">Home</Link>
              </div>
              <div>
                <Link to="/docs">Documentation</Link>
              </div>
              <div>
                <Link to="/blog">Blog</Link>
              </div>
              <div>
                <Link to="/docs/privacy-policy">Privacy Policy</Link>
              </div>
              <div>
              <OutboundLink
                  href="https://www.linkedin.com/company/sortdio"
                  target='_blank'
                >
                <span><img alt='LinkedIn' width={14} src='https://upload.wikimedia.org/wikipedia/commons/c/ca/LinkedIn_logo_initials.png'/></span>  LinkedIn
                </OutboundLink>
              </div>
              
            </div>
          </Col>
          <Col md={6} sm={24} xs={24}>
            <div className="footer-center">
              <h2>
                About Us
              </h2>
              <div>
              <b>Sortd.io</b> (ABN: 18633413313)<br/>
              Level 2/23 Foster Street, <br/>
              Surry Hills, <br/>
              NSW 2010 <br/>
              <br/>
              <a href='mailto:info@sortd.io'>info@sortd.io</a>
              </div>
              </div>
          </Col>
         
        </Row>
      </div>
      <div className="bottom-bar">
        Made for you with <span className="heart">❤</span> by
        <a target="_blank" rel="noopener noreferrer" href="https://sortd.io/">
          Sortd Consulting Pty Ltd
        </a>
      </div>
    </footer>
    );
  }
}

export default Footer;
