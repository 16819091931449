import React, { useEffect, useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import styles from './search.module.less';
import { useStaticQuery, graphql } from 'gatsby';
import { Input, Tag } from 'antd';
import {useKeyPress} from '../../../hooks';
import { useHover } from 'react-use';

export interface SearchProps {
  docsearchOptions?: {
    apiKey: string;
    indexName: string;
  };
}

function initDocSearch({
  docsearch,
}: {
  docsearch: any;
}) {
        if (!docsearch) {
        return;
      }
      docsearch({
        apiKey: 'fd0bb4bc99f2a2c8be59a43070083b24',
        appId: '4J3QEDMHZS', 
        indexName: 'dev_website',
        inputSelector: `.${styles.input}`,
        transformData(
          hits: {
            url: string;
          }[],
        ) {
          hits.forEach((hit) => {
            // eslint-disable-next-line  no-param-reassign
            hit.url = hit.url.replace('sortd.io', window.location.host);
            // eslint-disable-next-line  no-param-reassign
            hit.url = hit.url.replace('https:', window.location.protocol);
          });
          return hits;
        },
        debug: false, // Set debug to true if you want to inspect the dropdown
      });
}


const Search: React.FC<SearchProps> = () => {
  const inputRef = useRef(null)

  const searchBox = () => <input ref={inputRef} className={styles.input} id="search" placeholder={('Search Sortd...')} />;

  const [hoverRef, hovered] = useHover(searchBox);

  const handleKeyDown = (event) => {
    if(event.keyCode === 191){
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };
  useEffect(() => {
    window.addEventListener('keyup', handleKeyDown);

    // cleanup this component
    return () => {
      window.removeEventListener('keyup', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      import('docsearch.js').then(({ default: docsearch }) => {
        initDocSearch({
          docsearch,
        });
      });
    }
  }, []);
  return (
    <label className={styles.search} htmlFor="search">
      <SearchOutlined className={styles.icon} />
      {hoverRef}
      <Tag visible={hovered} style={{opacity: 0.5}}>/</Tag>
    </label>
  );
};

export default Search;
