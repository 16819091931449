import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Product from '../Product';
import { getProducts } from './getProducts';
import styles from '../product.module.less';
import { graphql, useStaticQuery } from 'gatsby';
import {HomeOutlined, LoginOutlined} from '@ant-design/icons';

interface ProductsProps {
  show: boolean;
  setHidden: () => void;
  rootDomain: string;
  className?: string;
}

const Products: React.FC<ProductsProps> = ({
  show,
  setHidden,
  rootDomain = '',
  className,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            products {
              name
              description
              productLink
              docsLink
              loginLink
              logo
            }
          }
        }
      }
    `,
  );
  const data = site.siteMetadata.products;
  return (
    <>
      <div
        className={classNames(styles.products, className, {
          [styles.show]: !!show,
        })}
      >
        <div className={styles.container}>
          <h3>{('Sortd Products')}</h3>
          <ul>
            {data
              .map(product => (
                <Product
                  key={product.name}
                  name={product.name}
                  slogan={''}
                  description={product.description}
                  url={product.productLink}
                  setHidden={setHidden}
                  icon={product.logo as string}
                  links={[
                    {
                      icon: <HomeOutlined />, 
                      title: 'Learn More',
                      url: product.productLink,
                      openExternal: product.productLink.includes('http'),
                    },
                    {
                      title: 'Documentation',
                      url: product.docsLink,
                      openExternal: product.docsLink.includes('http'),
                    },
                    {
                      icon: <LoginOutlined />, 
                      title: 'Login',
                      url: product.loginLink,
                      openExternal: product.loginLink.includes('http'),
                    },
                    ]
                  }
                />
              ))}
          </ul>
        </div>
      </div>
      <div className={styles.mask} />
    </>
  );
};

export default Products;
