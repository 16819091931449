import React, { useEffect } from 'react';
import PageLoading from 'sortd_sections/shared/PageLoading';
import styles from './layout.module.less';
import theme from 'theme/app/themeStyles';
import LayoutComponent from "components/layout" 
import SEO from "components/seo" 
import {ThemeProvider} from 'styled-components';
import { useStaticQuery, graphql, withPrefix } from 'gatsby';
import Navigation from 'sortd_sections/shared/Navigation'; 
import Footer from 'sortd_sections/shared/Footer';
import LayoutCom from "components/layout" 


interface LayoutProps {
  children: React.ReactElement<any>;
  location: Location;
  pageContext: any;
  footerProps: object;
}

const Layout: React.FC<LayoutProps> = ({ children, location, footerProps }) => {
  // https://github.com/gatsbyjs/gatsby/issues/13867#issuecomment-489481343
  if (location.pathname.includes('offline-plugin-app-shell-fallback')) {
    return <PageLoading />;
  }
  const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        navs {
          slug
          title 
          target
        }
        docsearchOptions {
          apiKey
          indexName
        }
      }
    }
  }
`;
const { site } = useStaticQuery(query);
const {
  siteMetadata: {
    navs = [],
    docsearchOptions,
  },
} = site;
const pathPrefix = withPrefix('/').replace(/\/$/, '');
const path = location.pathname.replace(pathPrefix, '');

const isHomePage = path === '/' || path.split('/')[1] === 'products' || path === '/docs' || path.includes('/blog') || path === '/login'
console.log(path)
const getSubTitle = () => {
  switch(location.pathname.split('/')[2]){
    case 'change-pro':
      return 'Change Pro'
    case 'requirements-pro':
      return 'Requirements Pro'
    default:
      return ''
  }
}

  return (
    <ThemeProvider theme={theme}> 
         <LayoutCom>

      <SEO title="Home" />
      <Navigation
        subTitle={getSubTitle()}
        navs={navs}
        docsearchOptions={docsearchOptions}
        path={path}
        isHomePage={isHomePage}
      />
      <main className={styles.main}>{children}</main>
      <Footer />
      </LayoutCom>
      </ThemeProvider>
  );
};

export default Layout;
