import { Link } from 'gatsby';
import React from 'react';
import ExternalLinkIcon from '../Navigation/ExternalLinkIcon';
import styles from '../product.module.less';

interface ProductProps {
  name?: string;
  setHidden: () => void;
  icon?: string;
  slogan?: string;
  description?: string;
  url?: string;
  links?: Array<{
    title: React.ReactNode;
    url?: string;
    icon?: React.ReactNode;
    openExternal?: boolean;
  }>;
  style?: React.CSSProperties;
}

const Product: React.FC<ProductProps> = ({
  name,
  icon,
  url = '',
  slogan,
  description,
  links = [],
  style,
  setHidden
}) => (
  <li className={styles.product} style={style}>
    <a href={url} target='_self' >
      <img alt={name} src={icon} />
    </a>
    <div className={styles.productContent}>
      <Link to={url} target='_self'onClick={setHidden} >
        <h4>
          {name}
          <span
            className={styles.productSlogan}
            style={{ opacity: 0.7 }}
          >
            {slogan}
          </span>
        </h4>
      </Link>
      <div className={styles.productDescription}>{description}</div>
      <div className={styles.productLinks}>
        {links.slice(0, 3).map(item => (
          item.openExternal || item.url.startsWith('http') ? (
            <a href={item.url} target="_blank" rel="noopener noreferrer">
              {item.title}
              <ExternalLinkIcon />
            </a>
          ) : (
            <Link to={item.url} onClick={setHidden}>{item.title}</Link>
          )
        ))}
      </div>
    </div>
  </li>
);


export default Product;
