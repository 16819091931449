import React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import classNames from 'classnames';
import shallowequal from 'shallowequal';
import ExternalLinkIcon from '../ExternalLinkIcon';
import styles from '../nav.module.less';

const getDocument = (navs: Nav[], slug = '') =>
  navs.find(doc => doc.slug === slug) || {
    title,
  };

export interface Nav {
  slug: string;
  order: number;
  title: string;
  target?: '_blank';
}

interface NavMenuItemsProps {
  navs: Nav[];
  path: string;
}

const capitalize = (s: string) => {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const NavMenuItems: React.FC<NavMenuItemsProps> = ({ navs = [], path }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            products {
              key
              loginLink
              docsLink
            }
          }
        }
      }
    `,
  );
  const products: Array<any> = site.siteMetadata.products;
  return (
    <>
      {navs.map((nav: Nav) => {
        const isProductAndLogin = path.split('/')[1] === 'products' && nav.slug === 'login'
        const isDocsAndLogin = path.split('/')[1] === 'docs' && nav.slug === 'login'
        const isProductAndDoc = path.split('/')[1] === 'products' && nav.slug === 'docs'

        const findProduct = products.find((product: any) => product.key === path.split('/')[2])

        const getHref = () => {
          if(findProduct){
            if(nav.slug === 'login'){
              return findProduct.loginLink
            } else if (nav.slug === 'docs') {
              return findProduct.docsLink
            } else {
              return nav.slug.startsWith('http')
              ? nav.slug
              : `/${nav.slug}`;
            }
           } else {
              return nav.slug.startsWith('http')
                ? nav.slug
                : `/${nav.slug}`;
            }
          }
        
        const href = getHref()


          const title = capitalize(
          getDocument(navs, nav.slug).title,
        );

        const className = classNames('header-menu-item-active', {
          [styles.activeItem]: path.split('/')[1] === (!href?.includes('http') && href?.split('/')[1])
        });
        return (
          <li key={title} className={className}>
            {nav.target === '_blank' || href?.startsWith('http') || isProductAndLogin ? (
              <a href={href} target="_blank" rel="noopener noreferrer">
                {title}
                <ExternalLinkIcon />
              </a>
            ) : (
              <Link to={href}>{title}</Link>
            )}
          </li>
        );
      })}
    </>
  );
};

export default NavMenuItems;
